window.EmberENV = (function(EmberENV, extra) {
  for (var key in extra) {
    EmberENV[key] = extra[key];
  }

  return EmberENV;
})(window.EmberENV || {}, {"FEATURES":{"EMBER_METAL_TRACKED_PROPERTIES":true},"EXTEND_PROTOTYPES":{"String":false,"Date":false},"admin":true,"_APPLICATION_TEMPLATE_WRAPPER":false,"_DEFAULT_ASYNC_OBSERVERS":true,"_JQUERY_INTEGRATION":false,"_TEMPLATE_ONLY_GLIMMER_COMPONENTS":true,"SHA":"49ff4dd8^","environment":"production","VERSION":"1.28.1"});

var runningTests = false;


